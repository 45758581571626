import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8)
    }
  },
  navLink: {
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.secondary.dark
    },
    '&.menu-item--no-dropdown': {
      paddingRight: 0
    }
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.primary.dark
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    fontSize: '14px',
    fontWeight: 600
  },
  listItemButton: {
    whiteSpace: 'nowrap',
    minWidth: '120px',
    maxHeight: '33px'
  },
  listItemIcon: {
    minWidth: 'auto',
  },
  popover: {
    padding: theme.spacing(4),
    border: theme.spacing(2),
    boxShadow: '0 0.5rem 2rem 2px rgba(116, 123, 144, 0.09)',
    minWidth: 350,
    marginTop: theme.spacing(2),
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    }
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark
  },
  logoImage: {
    width: '60px'
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0
    }
  },
  menuGroupItem: {
    paddingTop: 0
  },
  menuGroupTitle: {
    textTransform: 'uppercase'
  }
}));

const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoImage}>
        <a href="/" title="Qeerio">
          <StaticImage
            placeholder="blurred"
            src='../../../../assets/images/qeerio/icon.webp'
            alt="Icon guestlist"
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>
          <Link to="/"><ListItem
            className={clsx(
              classes.listItem
            )}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.listItemText, 'menu-item')}
            >
              Home
            </Typography>
          </ListItem>
          </Link>
          <Link to="/features">
            <ListItem
              className={clsx(
                classes.listItem
              )}
            >
              <Typography
                variant="body1"
                color="textPrimary"
                className={clsx(classes.listItemText, 'menu-item')}
              >
                Features
              </Typography>
            </ListItem>
          </Link>
          <Link to="/pricing"><ListItem
            className={clsx(
              classes.listItem
            )}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.listItemText, 'menu-item')}
            >
              Pricing
            </Typography>
          </ListItem>
          </Link>
          <Link to="/support"><ListItem
            className={clsx(
              classes.listItem
            )}
          >
            <Typography
              variant="body1"
              color="textPrimary"
              className={clsx(classes.listItemText, 'menu-item')}
            >
              Support
            </Typography>
          </ListItem>
          </Link>
          <ListItem className={clsx(classes.listItem, 'menu-item--no-dropdown')}>
            <Button
              variant="outlined"
              color="secondary"
              component="a"
              target="blank"
              href="https://guestlist-backoffice.qeerio.com/authentication/login"
              className={classes.listItemButton}
            >
              Log in
            </Button>
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar >
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired
};

export default Topbar;
