import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import {
  Typography,
  IconButton,
  Grid,
  List,
  ListItem,
  Box
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
// import PinterestIcon from '@material-ui/icons/Pinterest';
// import iconLogo from '../../../../assets/images/qeerio/icon.png';

import { Image } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0)
    },
    background: 'rgb(35,35,35)'
  },
  appStore: {
    maxWidth: '89px',
    marginRight: '40px'
  },
  googlePlayBtn: {
    border: '1px solid #A6A6A6',
    borderRadius: '5px',
    maxWidth: '108px'
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8)
    }
  },
  logoContainerItem: {
    paddingTop: 0
  },
  logoContainer: {
    width: 120,
    height: 32
  },
  logoImage: {
    width: '30%',
    height: '100%'
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1)
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent'
    },
    '&:last-child': {
      marginRight: 0
    }
  },
  icon: {
    fontSize: 24
  },
  menu: {
    display: 'flex'
  },
  menuItem: {
    margin: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white'
  },
  divider: {
    width: '100%'
  },
  navLink: {
    color: 'rgba(255,255,255,.6)'
  }
}));

const Footer = props => {
  const { pages, className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container spacing={4}>
          <Grid container item xs={12} md={2} justify="center" alignItems="center">
            <List disablePadding>
              <ListItem disableGutters>
                <a href="https://www.facebook.com/qeerio.app.events/" aria-label="Facebook" className={classes.socialIcon}>
                  <IconButton className={classes.socialIcon} aria-label="Facebook">
                    <FacebookIcon className={classes.icon} />
                  </IconButton>
                </a>
                <a href="https://www.instagram.com/qeerio.app/?hl=en" aria-label="Instagram" className={classes.socialIcon}>
                  <IconButton className={classes.socialIcon} aria-label="Instagram">
                    <InstagramIcon className={classes.icon} />
                  </IconButton>
                </a>
                <a href="https://twitter.com/qeerio_app" aria-label="Twitter" className={classes.socialIcon}>
                  <IconButton className={classes.socialIcon} aria-label="Twitter">
                    <TwitterIcon className={classes.icon} />
                  </IconButton>
                </a>
                <a href="https://www.linkedin.com/company/49084668/admin/" aria-label="Linkedin" className={classes.socialIcon}>
                  <IconButton className={classes.socialIcon} aria-label="Linkedin">
                    <LinkedInIcon className={classes.icon} />
                  </IconButton>
                </a>
              </ListItem>
            </List>
          </Grid>
          <Grid item container xs={12} md={8} justify="center" alignItems="center">
            <Typography variant="subtitle2">
              Copyright © Qeerio 2021.
            </Typography>
          </Grid>
          <Grid item container xs={12} md={2} justify="center" alignItems="center">
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center'
              }}
            >
              <Image
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  window.open('https://apps.apple.com/us/app/qeerio-guestlist/id1489870521')
                }}
                src={require('../../../../assets/images/qeerio/app-store.webp').default}
                alt="Get in on App Store"
                className={classes.appStore}
                lazy
              />
              <Image
                style={{
                  cursor: 'pointer'
                }}
                onClick={() => {
                  window.open('https://play.google.com/store/apps/details?id=com.qeerio.guestlist')
                }}
                src={require('../../../../assets/images/qeerio/play-store.webp').default}
                alt="Get in on Play Market"
                className={classes.googlePlayBtn}
                lazy
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
